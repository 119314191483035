import React from "react";
import Navbar from "../components/NavBar";
import styled from "styled-components";
import Video from "../assets/video/Worthwhile_new_Video.mp4";
import MobileVideo from "../assets/video/Worthwhile_new_Video_mobile.mp4";

export default function Home() {

  return (
    <>
      <Container>
        <div className="navbar">
          <Navbar menuIconChange={false} navigateLink={"/menu"}></Navbar>
        </div>
        <div className="background-video">
          <video autoPlay muted loop playsinline="true" disablePictureInPicture="true">
            {window.innerWidth >= 768 && <source src={Video} />}
            {window.innerWidth < 768 && <source src={MobileVideo} />}
          </video>
        </div>
      </Container>
    </>
  );
}

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .navbar {
    z-index: 1000;
  }
  .background-video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    video {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }  
  /* @media screen and (min-width: 900px) and (max-width: 1200px) {  }
  @media screen and (min-width: 750px) and (max-width: 900px) {  }
  @media screen and (min-width: 600px) and (max-width: 750px) {  }
  @media screen and (min-width: 450px) and (max-width: 600px) {  }
  @media screen and (min-width: 350px) and (max-width: 450px) {  }
  @media screen and (max-width: 350px) {  } */
`;
