import React from "react";
import Navbar from "../components/NavBar";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

export default function Services() {
  const navigate = useNavigate();
  return (
    <>
      <Container>
        <div className="navbar">
          <Navbar menuIconChange={true} navigateLink={"/menu"}></Navbar>
        </div>
        <div className="cards-container">
          <div className="card">
            <div className="box">
              <div className="content">
                <h3>Forex Trading</h3>
                <p>
                  Forex trading is a new form of investment that has been
                  growing rapidly becoming...
                </p>
                <button onClick={()=>navigate("/menu/services/forextrading")}>Read More</button>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="box">
              <div className="content">
                <h3>Stock</h3>
                <p>
                  Stock is a security interest in the assets of a corporation
                  and a precious commodity with direct ownership...
                </p>
                <button onClick={()=>navigate("/menu/services/stock")}>Read More</button>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="box">
              <div className="content">
                <h3>Property</h3>
                <p>
                  Whether you're looking to buy your first place or invest in
                  properties that are more established, we can help...
                </p>
                <button onClick={()=>navigate("/menu/services/property")}>Read More</button>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="box">
              <div className="content">
                <h3>Bonds</h3>
                <p>
                  Bonds are a type of investment in which you are lending money
                  to a company, or the government and they give you...
                </p>
                <button onClick={()=>navigate("/menu/services/bonds")}>Read More</button>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: black;
  display: flex;
  flex-direction: column;
  .navbar {
    z-index: 1000;
  }
  .cards-container {
    flex: 1;
    display: flex;
    /* flex-direction: row; */
    justify-content: center;
    /* align-content: center; */
    /* align-items: center; */
    flex-wrap: wrap;
    max-width: 100%;
    /* max-height: 90%; */
    gap: 50px;
    /* margin: 40px 0; */
    overflow: scroll;
    &::-webkit-scrollbar {
      width: 0.2rem;
      &-thumb {
        background-color: black;
        width: 0.1rem;
        border-radius: 1rem;
      }
    }
    padding: 75px 20px;
    /* padding: auto; */
    /* margin-bottom: 20px; */
    /* margin: auto; */
    .card {
      background-color: white;
      position: relative;
      min-width: 260px;
      height: 410px;
      box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
        inset -5px -5px 5px rgba(255, 255, 255, 0.5),
        5px 5px 5px rgba(0, 0, 0, 0.05), -5px -5px 5px rgba(255, 255, 255, 0.5);
      border-radius: 15px;
      /* margin: 30px; */
      .box {
        position: absolute;
        top: 10px;
        left: 10px;
        right: 10px;
        bottom: 10px;
        background: linear-gradient(45deg, #b49961, #722c0c);
        /* border: 2px solid #1e1f23; */
        border-radius: 15px;
        box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
        transition: 0.5s;
        display: flex;
        justify-content: center;
        align-items: center;

        .content {
          color: white;
          padding: 20px;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 2.5rem;

          h3 {
            font-size: 1.8rem;
            pointer-events: none;
            /* color: white; */
          }
          p {
            font-size: 1.1rem;
            font-weight: 400;
            pointer-events: none;
            /* text-align: center; */
          }
          button {
            padding: 10px 20px;
            background: #f4a460;
            border-radius: 20px;
            font-weight: 600;
            color: white;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
            border: none;
            cursor: pointer;
            /* margin-top: 5px; */
          }
        }
        &:hover {
          transform: translateY(-50px);
          box-shadow: 0 40px 70px rgba(0, 0, 0, 0.5);
          button {
            background-color: #cd7f32;
          }
        }
      }
    }
  }
  @media screen and (min-width: 600px) and (max-width: 1080px) {
    .cards-container {
      align-content: flex-start;
      padding: 80px 20px;
      .card {
        .box {
          .content {
            h3 {
            }
            p {
            }
            button {
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 450px) and (max-width: 600px) {
    .cards-container {
      .card {
        .box {
          .content {
            h3 {
            }
            p {
            }
            button {
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 350px) and (max-width: 450px) {
    .cards-container {
      padding: 55px 20px;
      margin-bottom: 20px;
      .card {
        .box {
          .content {
            h3 {
            }
            p {
            }
            button {
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 350px) {
    .cards-container {
      padding: 55px 20px;
      margin-bottom: 20px;
      .card {
        min-width: 230px;
        height: 390px;
        .box {
          .content {
            h3 {
            }
            p {
            }
            button {
            }
          }
        }
      }
    }
  }
`;
