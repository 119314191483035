import React from "react";
import Navbar from "../components/NavBar";
import styled from "styled-components";
import stock from "../assets/images/services/Stock.jpg";

export default function Stock() {
  return (
    <>
      <Container>
        <div className="navbar">
          <Navbar
            menuIconChange={true}
            navigateLink={"/menu/services"}
          ></Navbar>
        </div>
        <div className="stock">
          <div className="description">
            <h2>Stock</h2>
            <p>
            Stock is a security interest in the assets of a corporation and a precious commodity with direct ownership rights to money. The stock represents the ownership stake that the shareholder has in the corporation. Investing in stocks is the most common and popular way to invest your money. Your investment can grow over time, most likely much faster than the rate of inflation, thereby allowing you to make more money each year than you could if you simply kept it in cash. Invest your money in high-quality stocks that are likely to perform well in the future.
            </p>
          </div>
          <div className="stock-image">
            <div className="img-container">
              <img src={stock} alt="Stock" />
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: black;
  display: flex;
  flex-direction: column;
  .navbar {
    z-index: 1000;
  }
  .stock {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 20px;
    /* padding: 20px 10px; */
    overflow: scroll;
    &::-webkit-scrollbar {
      width: 0.2rem;
      &-thumb {
        background-color: black;
        width: 0.1rem;
        border-radius: 1rem;
      }
    }
    /* flex-wrap: wrap; */
    .description {
      color: white;
      width: 30%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      h2 {
        font-size: 1.7rem;
        font-weight: 500;
      }
      p {
        font-size: 1rem;
        text-align: justify;
        font-weight: 300;
        line-height: 1.5rem;
        letter-spacing: 0.05rem;
        /* max-height: 100%; */
        /* margin-top: 5rem; */
      }
    }
    .stock-image {
      width: 35%;
      .img-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        height: 300px;
        overflow: hidden;
        border-radius: 15px;
        box-shadow: 2px 2px 4px rgba(255, 255, 255, 0.5);
        img {
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
  @media screen and (min-width: 750px) and (max-width: 1080px) {
    .stock {
      padding: 80px 0;
      /* margin-bottom: 20px; */
      .description {
        width: 35%;
        h2 {
          font-size: 1.3rem;
          font-weight: 500;
        }
        p {
          font-size: 1rem;
          text-align: justify;
          font-weight: 300;
          line-height: 1.3rem;
          letter-spacing: 0.01rem;
        }
      }
      .stock-image {
        width: 35%;
        .img-container {
          height: 250px;
        }
      }
    }
  }
  @media screen and (min-width: 600px) and (max-width: 750px) {
    .stock {
      flex-direction: column;
      justify-content: flex-start;
      padding: 50px 0;
      margin-bottom: 20px;
      .description {
        width: 60%;
        h2 {
          font-size: 1.3rem;
          font-weight: 500;
          text-align: center;
        }
        p {
          font-size: 1rem;
          text-align: justify;
          font-weight: 300;
          line-height: 1.3rem;
          letter-spacing: 0.01rem;
        }
      }
      .stock-image {
        width: 55%;
        .img-container {
          height: 250px;
        }
      }
    }
  }
  @media screen and (min-width: 450px) and (max-width: 600px) {
    .stock {
      flex-direction: column;
      justify-content: flex-start;
      padding: 50px 0;
      margin-bottom: 20px;
      .description {
        width: 60%;
        h2 {
          font-size: 1.3rem;
          font-weight: 500;
          text-align: center;
        }
        p {
          font-size: 1rem;
          text-align: justify;
          font-weight: 300;
          line-height: 1.3rem;
          letter-spacing: 0.01rem;
        }
      }
      .stock-image {
        width: 55%;
        .img-container {
          height: 250px;
        }
      }
    }
  }
  @media screen and (min-width: 350px) and (max-width: 450px) {
    .stock {
      flex-direction: column;
      justify-content: flex-start;
      padding: 30px 0;
      margin-bottom: 30px;
      .description {
        width: 80%;
        h2 {
          font-size: 1.3rem;
          font-weight: 500;
          text-align: center;
        }
        p {
          font-size: 0.9rem;
          text-align: justify;
          font-weight: 300;
          line-height: 1.2rem;
          letter-spacing: 0.01rem;
        }
      }
      .stock-image {
        width: 70%;
        .img-container {
          height: 200px;
        }
      }
    }
  }
  @media screen and (max-width: 350px) {
    .stock {
      flex-direction: column;
      justify-content: flex-start;
      padding: 30px 0;
      margin-bottom: 20px;
      .description {
        width: 80%;
        h2 {
          font-size: 1.2rem;
          font-weight: 500;
          text-align: center;
        }
        p {
          font-size: 0.8rem;
          text-align: justify;
          font-weight: 300;
          line-height: 1.2rem;
          letter-spacing: 0.01rem;
        }
      }
      .stock-image {
        width: 70%;
        .img-container {
          height: 190px;
        }
      }
    }
  }
`;

