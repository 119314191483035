import React from "react";
import Navbar from "../components/NavBar";
import styled from "styled-components";
import { Link} from "react-router-dom";

export default function Menu() {
  return (
    <>
      <Container>
        <div className="navbar">
          <Navbar menuIconChange={true} navigateLink={"/"}></Navbar>
        </div>
        <div className="menu">
          <div className="container">
            <h2>
              <Link to="/menu/about">About</Link>
            </h2>
            <h2>
              <Link to="/menu/services">Services</Link>
            </h2>
            <h2>
              <Link to="/menu/contact">Contact</Link>
            </h2>
          </div>
        </div>
      </Container>
    </>
  );
}

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: black;
  display: flex;
  flex-direction: column;
  .navbar {
    z-index: 1000;
  }
  .menu {
    flex: 1;
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      /* justify-content: center; */
      gap: 0.7rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      h2 {
        font-size: 1.9rem;
        cursor: pointer;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.2rem;
        a {
          color: white;
          text-decoration: none;
          &:hover {
            color: lightgray;
          }
        }
      }
    }
  }
  @media screen and (min-width: 600px) and (max-width: 1080px) {
    .menu {
      .container {
        h2 {
          font-size: 1.6rem;
          letter-spacing: 0.1rem;
        }
      }
    }
  }
  @media screen and (min-width: 450px) and (max-width: 600px) {
    .menu {
      .container {
        h2 {
          font-size: 1.3rem;
          letter-spacing: 0.1rem;
        }
      }
    }
  }
  @media screen and (min-width: 350px) and (max-width: 450px) {
    .menu {
      .container {
        h2 {
          font-size: 1.2rem;
          letter-spacing: 0.1rem;
        }
      }
    }
  }
  @media screen and (max-width: 350px) {
    .menu {
      .container {
        h2 {
          font-size: 0.8rem;
          letter-spacing: 0.1rem;
        }
      }
    }
  }
`;
