import React from "react";
import Navbar from "../components/NavBar";
import styled from "styled-components";

export default function About() {
  return (
    <>
      <Container>
        <div className="navbar">
          <Navbar menuIconChange={true} navigateLink={"/menu"}></Navbar>
        </div>
        <div className="text">
          <p>
            We are a leading fintech and investment company, providing access to
            global financial markets through our platform. We pride ourselves on
            being different, offering our clients and traders a bespoke service.
            Our mission is to be the most respected and successful financial
            service provider in the foreign exchange, Stock market, bonds,
            Property investment in a fair, transparent, and efficient way by
            putting clients first and creating long-term partnerships with our
            clients, employees, investors, and suppliers through our commitment
            to honesty, integrity, financial strength and diversity. We take
            pride in offering professional advice through the whole planning
            process, helping you achieve your goals. Join us today! For safe &
            secure investment.
          </p>
        </div>
      </Container>
    </>
  );
}

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: black;
  display: flex;
  flex-direction: column;
  .navbar {
    z-index: 1000;
  }
  .text {
    flex: 1;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    width: 50%;
    align-self: center;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 0.2rem;
      &-thumb {
        background-color: black;
        width: 0.1rem;
        border-radius: 1rem;
      }
    }
    p {
      font-size: 1.3rem;
      color: white;
      text-align: justify;
      font-weight: 500;
      line-height: 2rem;
      letter-spacing: 0.05rem;
      max-height: 100%;
      margin-top: 5rem;
    }
  }
  @media screen and (min-width: 600px) and (max-width: 1080px) {
    .text {
      width: 60%;
      p {
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 2.5rem;
        letter-spacing: 0.01rem;
        margin-top: 7rem;
      }
    }
  }
  @media screen and (min-width: 450px) and (max-width: 600px) {
    .text {
      width: 70%;
      p {
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.5rem;
        letter-spacing: 0.04rem;
        margin-top: 5rem;
      }
    }
  }
  @media screen and (min-width: 350px) and (max-width: 450px) {
    .text {
      width: 80%;
      p {
        font-size: 0.9rem;
        font-weight: 500;
        line-height: 1.3rem;
        margin-top: 1.5rem;
      }
    }
  }
  @media screen and (max-width: 350px) {
    .text {
      width: 80%;
      p {
        font-size: 0.9rem;
        font-weight: 500;
        line-height: 1.3rem;
        margin-top: 1rem;
      }
    }
    }
`;
