import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Menu from "./pages/Menu";
import About from "./pages/About";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import Forextrading from "./components/Forextrading";
import Stock from "./components/Stock";
import Property from "./components/Property";
import Bonds from "./components/Bonds";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/menu" element={<Menu />} />
          <Route path="/menu/about" element={<About />} />
          <Route path="/menu/services" element={<Services />} />
          <Route path="/menu/contact" element={<Contact />} />
          <Route path="/menu/services/forextrading" element={<Forextrading />} />
          <Route path="/menu/services/stock" element={<Stock />} />
          <Route path="/menu/services/property" element={<Property />} />
          <Route path="/menu/services/bonds" element={<Bonds />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
