import React from "react";
import Navbar from "../components/NavBar";
import styled from "styled-components";
import { useState, useEffect, useRef } from "react";
import { SlLocationPin } from "react-icons/sl";
import { TfiEmail } from "react-icons/tfi";
import { LiaPhoneVolumeSolid } from "react-icons/lia";
import { FaCheckCircle } from "react-icons/fa";
import { FaTimesCircle } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { emailRoute } from "../utils/APIRoutes";
import axios from "axios";

export default function Contact() {
  const [formheight, setFormHeight] = useState(0);
  const [heightdifference, setHeightDifference] = useState("true");
  const [values, setValues] = useState({
    firstname: "",
    lastname: "",
    email: "",
    mobile: "",
    message: "",
  });
  const [formvisibility, setformVisibility] = useState(true);
  const [popupvisibility, setpopupVisibility] = useState("false");
  const [emailstatus, setEmailStatus] = useState("true");
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailRef = useRef(null);
  const mobileRef = useRef(null);
  const messageRef = useRef(null);

  const toastOptions = {
    position: "bottom-right",
    autoClose: 5000,
    pauseOnHover: true,
    draggable: true,
    theme: "light",
  };

  useEffect(() => {
    const formElement = document.querySelector(".form");
    const formContainer = document.querySelector(".formcontainer");

    if (formElement) {
      const height = formElement.clientHeight;
      setFormHeight(height);
      const containerHeight = formContainer.clientHeight;
      const hgtDiff = height - containerHeight;
      if (hgtDiff > 40) {
        setHeightDifference("true");
      } else {
        setHeightDifference("false");
      }
    }
  }, []);

  function handleChange(event) {
    setValues({ ...values, [event.target.name]: event.target.value });
  }

  async function handleSubmit() {
    if (handleValidation()) {
      const { firstname, lastname, email, mobile, message } = values;

      firstNameRef.current.value = "";
      lastNameRef.current.value = "";
      emailRef.current.value = "";
      mobileRef.current.value = "";
      messageRef.current.value = "";
      setValues({
        firstname: "",
        lastname: "",
        email: "",
        mobile: "",
        message: "",
      });

      // const { data } = await axios.post(emailRoute, {
      //   firstname,
      //   lastname,
      //   email,
      //   mobile,
      //   message,
      // });

      const { data } = await axios({
        method: "post",
        url: emailRoute,
        data: { firstname, lastname, email, mobile, message },
        headers: {
          "Content-Type": "application/json"
        },
      });
      if (data.status === false) {
        setformVisibility(false);
        setpopupVisibility("true");
        setEmailStatus("false");
      }
      if (data.status === true) {
        setformVisibility(false);
        setpopupVisibility("true");
        setEmailStatus("true");
      }      
    }
  }

  function handleValidation() {
    const { firstname, lastname, email, mobile, message } = values;

    function isValidEmail(email) {
      const emailRegex = /^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return emailRegex.test(email);
    }

    function validateMobileNumber(mobile) {
      const regex = /^(\+\d{1,3}\s?)?(\d{10})$/;
      return regex.test(mobile);
    }

    function isValidName(name) {
      const regex = /^[A-Za-z]+\s?[A-Za-z]+$/;
      return regex.test(name);
    }

    if (firstname.trim() === "") {
      toast.error("First Name is required", toastOptions);
      return false;
    } else if (lastname.trim() === "") {
      toast.error("Last Name is required", toastOptions);
      return false;
    } else if (email.trim() === "") {
      toast.error("Email Address is required", toastOptions);
      return false;
    } else if (mobile.trim() === "") {
      toast.error("Mobile number is required", toastOptions);
      return false;
    } else if (message.trim() === "") {
      toast.error("Message is required", toastOptions);
      return false;
    } else if (!isValidName(firstname)) {
      toast.error("First name can only contain letters", toastOptions);
      return false;
    } else if (!isValidName(lastname)) {
      toast.error("Last name can only contain letters", toastOptions);
      return false;
    } else if (!isValidEmail(email)) {
      toast.error("Enter a valid Email", toastOptions);
      return false;
    } else if (!validateMobileNumber(mobile)) {
      toast.error("Enter a valid Mobile No.", toastOptions);
      return false;
    }
    return true;
  }

  return (
    <>
      <Container
        formheight={formheight}
        heightdifference={heightdifference}
        emailstatus={emailstatus}
        popupvisibility={popupvisibility}
      >
        <div className="navbar">
          <Navbar menuIconChange={true} navigateLink={"/menu"}></Navbar>
        </div>
        <div className="form">
          <div
            className={`formcontainer ${!formvisibility ? "formdisplay" : ""}`}
          >
            <div className="contactInfo">
              <div className="contactInfo-container">
                <h2>Contact Info</h2>
                <div className="address" id="infodetail">
                  <div className="icon">
                    <SlLocationPin></SlLocationPin>
                  </div>
                  <div className="description">
                    17 Hanover square, London <br />
                    W1S 1BN <br />
                    UK
                  </div>
                </div>
                <div className="email" id="infodetail">
                  <div className="icon">
                    <TfiEmail></TfiEmail>
                  </div>
                  <div className="description">info@worthwhilefintech.com</div>
                </div>
                <div className="phone" id="infodetail">
                  <div className="icon">
                    <LiaPhoneVolumeSolid></LiaPhoneVolumeSolid>
                  </div>
                  <div className="description">+44 7459452456</div>
                </div>
              </div>
            </div>
            <div className="contactForm">
              <h2>Send a Message</h2>
              <div className="formBox">
                <div className="inputBox w50">
                  <input
                    type="text"
                    name="firstname"
                    id="firstname"
                    ref={firstNameRef}
                    onChange={handleChange}
                    required
                  />
                  <span>First Name</span>
                </div>
                <div className="inputBox w50">
                  <input
                    type="text"
                    name="lastname"
                    id="lastname"
                    ref={lastNameRef}
                    onChange={handleChange}
                    required
                  />
                  <span>Last Name</span>
                </div>
                <div className="inputBox w50">
                  <input
                    type="text"
                    name="email"
                    id="email"
                    ref={emailRef}
                    onChange={handleChange}
                    required
                  />
                  <span>Email Address</span>
                </div>
                <div className="inputBox w50">
                  <input
                    type="text"
                    name="mobile"
                    id="mobile"
                    ref={mobileRef}
                    onChange={handleChange}
                    required
                  />
                  <span>Mobile Number</span>
                </div>
                <div className="inputBox w100">
                  <textarea
                    name="message"
                    id="message"
                    ref={messageRef}
                    onChange={handleChange}
                    required
                  ></textarea>
                  <span>Write your message here...</span>
                </div>
                <div className="inputBox w100">
                  <input type="submit" onClick={handleSubmit} value="Send" />
                </div>
              </div>
            </div>
          </div>
          <div
            className={`popup ${
              popupvisibility === "false" ? "popupdisplay" : ""
            }`}
          >
            <div className="tickContainer">
              {emailstatus === "true" ? (
                <FaCheckCircle></FaCheckCircle>
              ) : (
                <FaTimesCircle></FaTimesCircle>
              )}
            </div>
            <h2>{emailstatus === "true" ? "Thank You!" : ""}</h2>
            <p>
              {emailstatus === "true"
                ? "Your details has been successfully submitted. Thanks!"
                : "Oops! We're sorry, but it seems like there was an issue sending your details. Please try again. If you continue to experience problems,please don't hesitate to contact us directly at +447459452456. Our team is here to assist you in any way we can."}
            </p>
            <button
              type="button"
              onClick={() => {
                setpopupVisibility("false");
                setformVisibility(true);
              }}
            >
              OK
            </button>
          </div>
        </div>
      </Container>
      <ToastContainer />
    </>
  );
}

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: black;
  display: flex;
  flex-direction: column;
  .navbar {
    z-index: 1000;
  }
  .form {
    position: relative;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: ${(props) =>
      props.popupvisibility === "true"
        ? "center"
        : props.heightdifference === "true"
        ? "center"
        : "flex-start"};
    padding: ${(props) =>
      props.popupvisibility === "true"
        ? ""
        : props.heightdifference === "true"
        ? ""
        : "50px 0px"};
    width: 100%;
    min-height: ${(props) => props.formheight}px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 0.2rem;
      &-thumb {
        background-color: black;
        width: 0.1rem;
        border-radius: 1rem;
      }
    }
    .formcontainer {
      position: relative;
      min-width: 1000px;
      min-height: 480px;
      display: flex;
      z-index: 1000;
      .contactInfo {
        position: absolute;
        top: 60px;
        width: 350px;
        height: calc(100% - 120px);
        background: linear-gradient(145deg, #b49961, #722c0c);
        z-index: 1;
        padding: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-shadow: 0 20px 25px rgba(0, 0, 0, 0.15);
        .contactInfo-container {
          height: 100%;
          width: 100%;
          color: white;
          display: flex;
          flex-direction: column;
          gap: 22px;
          h2 {
            margin-bottom: 10px;
            margin-top: 10px;
          }
          #infodetail {
            display: flex;
            gap: 25px;
            justify-content: flex-start;
            .icon {
              font-size: 25px;
            }
            .description {
            }
          }
        }
      }
      .contactForm {
        position: absolute;
        padding: 70px 50px;
        padding-left: 250px;
        margin-left: 150px;
        width: calc(100% - 150px);
        height: 100%;
        background: #fff;
        box-shadow: 0 50px 50px rgba(0, 0, 0, 0.25);
        h2 {
          color: #0f3959;
          font-size: 24px;
          font-weight: 500;
        }
        .formBox {
          position: relative;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          padding-top: 30px;
          .inputBox {
            position: relative;
            margin-bottom: 35px;
            input,
            textarea {
              width: 100%;
              resize: none;
              padding: 5px 0;
              font-size: 18px;
              font-weight: 300;
              color: #333;
              border: none;
              outline: none;
              border-bottom: 1px solid #777;
            }
            .textarea {
              height: 120px;
            }
            span {
              position: absolute;
              left: 0;
              padding: 5px 0;
              pointer-events: none;
              font-size: 18px;
              font-weight: 300;
              transition: 0.3s;
            }
            input:focus ~ span,
            input:valid ~ span,
            textarea:focus ~ span,
            textarea:valid ~ span {
              transform: translateY(-20px);
              font-size: 12px;
              font-weight: 400;
              letter-spacing: 1px;
              color: #095a55;
              font-weight: 500;
            }
            input[type="submit"] {
              position: relative;
              cursor: pointer;
              background: linear-gradient(15deg, #b49961, #722c0c);
              color: #fff;
              border: none;
              max-width: 150px;
              padding: 12px;
              &:hover {
                background: #722c0c;
              }
            }
          }
          .w50 {
            width: 47%;
          }
          .w100 {
            width: 100%;
          }
        }
      }
    }
    .formdisplay {
      display: none;
    }
    .popup {
      width: 350px;
      background: #fff;
      border-radius: 6px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      padding: 50px 20px 20px 20px;
      .tickContainer {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        color: ${(props) => (props.emailstatus === "true" ? "#6fd649" : "red")};
        background: white;
        width: 70px;
        height: 70px;
        border-radius: 50%;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 100%;
          height: 100%;
        }
      }
      h2 {
        color: black;
        font-size: 30px;
        font-weight: 500;
      }
      p {
        color: black;
        text-align: center;
        padding: 0px 10px;
        font-weight: 400;
        line-height: 1.2rem;
        letter-spacing: 0.01rem;
      }
      button {
        width: 100%;
        margin-top: 15px;
        padding: 10px 0;
        background: ${(props) =>
          props.emailstatus === "true" ? "#6fd649" : "red"};
        color: #fff;
        border: 0;
        outline: none;
        font-size: 18px;
        font-weight: 600;
        border-radius: 4px;
        cursor: pointer;
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
      }
    }
    .popupdisplay {
      display: none;
    }
  }
  @media screen and (min-width: 900px) and (max-width: 1200px) {
    .form {
      .formcontainer {
        min-width: 800px;
        min-height: 400px;
        .contactInfo {
          top: 45px;
          width: 300px;
          height: calc(100% - 90px);
          padding: 20px;
          .contactInfo-container {
            gap: 20px;
            h2 {
              margin-top: 15px;
            }
            #infodetail {
              gap: 15px;
              .icon {
                font-size: 22px;
              }
              .description {
                font-size: 15px;
              }
            }
          }
        }
        .contactForm {
          padding: 55px 30px;
          padding-left: 180px;
          h2 {
            font-size: 20px;
          }
          .formBox {
            padding-top: 20px;
            .inputBox {
              margin-bottom: 30px;
              input,
              textarea {
                padding: 5px 0;
                font-size: 15px;
              }
              .textarea {
                height: 120px;
              }
              span {
                font-size: 16px;
              }
              input:focus ~ span,
              input:valid ~ span,
              textarea:focus ~ span,
              textarea:valid ~ span {
              }
              input[type="submit"] {
                max-width: 115px;
                padding: 10px;
              }
            }
            .w50 {
              width: 47%;
            }
            .w100 {
              width: 100%;
            }
          }
        }
      }
      .popup {
        .tickContainer {
        }
        h2 {
        }
        p {
        }
        button {
        }
      }
    }
  }
  @media screen and (min-width: 750px) and (max-width: 900px) {
    .form {
      .formcontainer {
        min-width: 450px;
        min-height: 725px;
        display: flex;
        z-index: 1000;
        background: red;
        .contactInfo {
          top: 0px;
          width: 100%;
          height: 325px;
          z-index: 1;
          padding: 40px 70px;
          .contactInfo-container {
            h2 {
              text-align: center;
            }
            #infodetail {
              gap: 20px;
              .icon {
                font-size: 25px;
                margin-left: 10px;
              }
              .description {
              }
            }
          }
        }
        .contactForm {
          top: 325px;
          left: 0px;
          padding: 40px 40px;
          margin-left: 0px;
          width: 100%;
          height: 400px;
          h2 {
            text-align: center;
          }
          .formBox {
            padding-top: 30px;
            .inputBox {
              position: relative;
              margin-bottom: 35px;
              input,
              textarea {
              }
              .textarea {
                height: 120px;
              }
              span {
              }
              input:focus ~ span,
              input:valid ~ span,
              textarea:focus ~ span,
              textarea:valid ~ span {
              }
              input[type="submit"] {
                margin: 0px 110px;
              }
            }
            .w50 {
            }
            .w100 {
            }
          }
        }
      }
      .formdisplay {
        display: none;
      }
      .popup {
        .tickContainer {
        }
        h2 {
        }
        p {
        }
        button {
        }
      }
      .popupdisplay {
        display: none;
      }
    }
  }
  @media screen and (min-width: 600px) and (max-width: 750px) {
    .form {
      .formcontainer {
        min-width: 400px;
        min-height: 725px;
        .contactInfo {
          top: 0px;
          width: 100%;
          height: 325px;
          padding: 40px 50px;
          .contactInfo-container {
            h2 {
              text-align: center;
            }
            #infodetail {
              gap: 20px;
              .icon {
                font-size: 25px;
                margin-left: 10px;
              }
              .description {
              }
            }
          }
        }
        .contactForm {
          top: 325px;
          left: 0px;
          padding: 40px 40px;
          margin-left: 0px;
          width: 100%;
          height: 400px;
          h2 {
            text-align: center;
          }
          .formBox {
            padding-top: 30px;
            .inputBox {
              position: relative;
              margin-bottom: 35px;
              input,
              textarea {
              }
              .textarea {
                height: 120px;
              }
              span {
              }
              input:focus ~ span,
              input:valid ~ span,
              textarea:focus ~ span,
              textarea:valid ~ span {
              }
              input[type="submit"] {
                margin: 0px 92.5px;
                max-width: 135px;
              }
            }
            .w50 {
            }
            .w100 {
            }
          }
        }
      }
      .popup {
        .tickContainer {
        }
        h2 {
        }
        p {
        }
        button {
        }
      }
    }
  }
  @media screen and (min-width: 450px) and (max-width: 600px) {
    .form {
      .formcontainer {
        min-width: 350px;
        min-height: 760px;
        .contactInfo {
          top: 0px;
          width: 100%;
          height: 300px;
          padding: 30px 26px;
          .contactInfo-container {
            h2 {
              text-align: center;
            }
            #infodetail {
              gap: 13px;
              .icon {
                font-size: 25px;
                margin-left: 10px;
              }
              .description {
              }
            }
          }
        }
        .contactForm {
          top: 300px;
          left: 0px;
          padding: 30px 25px;
          margin-left: 0px;
          width: 100%;
          height: 460px;
          h2 {
            text-align: center;
          }
          .formBox {
            padding-top: 25px;
            .inputBox {
              position: relative;
              margin-bottom: 25px;
              input,
              textarea {
              }
              .textarea {
                height: 120px;
              }
              span {
              }
              input:focus ~ span,
              input:valid ~ span,
              textarea:focus ~ span,
              textarea:valid ~ span {
              }
              input[type="submit"] {
                margin: 0px 92.5px;
                max-width: 115px;
                padding: 8px;
              }
            }
            .w50 {
              width: 100%;
            }
            .w100 {
            }
          }
        }
      }
      .popup {
        width: 280px;
        gap: 15px;
        .tickContainer {
          width: 60px;
          height: 60px;
        }
        h2 {
          font-size: 25px;
        }
        p {
        }
        button {
          font-size: 15px;
          margin-top: 10px;
        }
      }
    }
  }
  @media screen and (min-width: 350px) and (max-width: 450px) {
    .form {
      .formcontainer {
        min-width: 280px;
        min-height: 650px;
        .contactInfo {
          top: 0px;
          width: 100%;
          height: 230px;
          padding: 20px 26px;
          box-shadow: none;
          .contactInfo-container {
            gap: 19px;
            h2 {
              text-align: center;
              margin-bottom: 5px;
              margin-top: 0px;
            }
            #infodetail {
              gap: 13px;
              .icon {
                font-size: 20px;
                margin-left: 5px;
              }
              .description {
                font-size: 13px;
              }
            }
          }
        }
        .contactForm {
          top: 230px;
          left: 0px;
          padding: 20px 25px;
          margin-left: 0px;
          width: 100%;
          height: 420px;
          h2 {
            text-align: center;
            font-size: 20px;
          }
          .formBox {
            padding-top: 25px;
            .inputBox {
              position: relative;
              margin-bottom: 25px;
              input,
              textarea {
                font-size: 15px;
              }
              .textarea {
                height: 120px;
              }
              span {
                font-size: 15px;
              }
              input:focus ~ span,
              input:valid ~ span,
              textarea:focus ~ span,
              textarea:valid ~ span {
              }
              input[type="submit"] {
                margin: 0px 67.5px;
                max-width: 95px;
                padding: 8px;
              }
            }
            .w50 {
              width: 100%;
            }
            .w100 {
            }
          }
        }
      }
      .popup {
        width: 240px;
        gap: 13px;
        padding: 43px 20px 20px 20px;
        .tickContainer {
          width: 50px;
          height: 50px;
        }
        h2 {
          font-size: 22px;
        }
        p {
          font-size: 15px;
        }
        button {
          font-size: 15px;
          margin-top: 10px;
        }
      }
    }
  }
  @media screen and (max-width: 350px) {
    .form {
      .formcontainer {
        min-width: 240px;
        min-height: 580px;
        .contactInfo {
          top: 0px;
          width: 100%;
          height: 200px;
          padding: 20px 18px;
          box-shadow: none;
          .contactInfo-container {
            gap: 15px;
            h2 {
              text-align: center;
              margin-bottom: 0px;
              margin-top: 0px;
              font-size: 18px;
            }
            #infodetail {
              gap: 8px;
              .icon {
                font-size: 18px;
                margin-left: 0px;
              }
              .description {
                font-size: 12.5px;
              }
            }
          }
        }
        .contactForm {
          top: 200px;
          left: 0px;
          padding: 20px 18px;
          margin-left: 0px;
          width: 100%;
          height: 380px;
          h2 {
            text-align: center;
            font-size: 17px;
          }
          .formBox {
            padding-top: 21px;
            .inputBox {
              position: relative;
              margin-bottom: 20px;
              input,
              textarea {
                font-size: 15px;
              }
              .textarea {
                height: 120px;
              }
              span {
                font-size: 15px;
              }
              input:focus ~ span,
              input:valid ~ span,
              textarea:focus ~ span,
              textarea:valid ~ span {
              }
              input[type="submit"] {
                margin: 0px 62px;
                max-width: 80px;
                padding: 7px;
              }
            }
            .w50 {
              width: 100%;
            }
            .w100 {
            }
          }
        }
      }
      .popup {
        width: 200px;
        gap: 13px;
        padding: 43px 10px 20px 10px;
        .tickContainer {
          width: 50px;
          height: 50px;
        }
        h2 {
          font-size: 20px;
        }
        p {
          font-size: 13px;
        }
        button {
          width: 90%;
          font-size: 13px;
          margin-top: 10px;
        }
      }
    }
  }
`;
