import React from "react";
import styled from "styled-components";
import CompanyLogo from "../assets/images/company-pics/logo280.png";
import TitleLogo from "../assets/images/company-pics/logo.png";
import { GiHamburgerMenu } from "react-icons/gi";
import {RxCross2} from "react-icons/rx";
import { useNavigate } from "react-router-dom";

export default function Navbar({menuIconChange, navigateLink}) {
  const navigate = useNavigate();

  function handleToggleClick() {
    if(menuIconChange){
      navigate(navigateLink);
    }else {
      navigate(navigateLink);
    }
  }

  return (
    <>
      <Nav>
        <div className="toggle" onClick={handleToggleClick}>
          <div className={`container ${!menuIconChange? 'burger' : ""}`}>
            {
              menuIconChange ? <RxCross2></RxCross2> : <GiHamburgerMenu></GiHamburgerMenu>
            }            
          </div>
        </div>
        <div className="title" onClick={()=>navigate("/")}>
          <div className="container">
            <img className="titleImg" src={TitleLogo} alt="title" />
          </div>
        </div>
        <div className="brand" onClick={()=>navigate("/")}>
          <div className="container">
            <img className="brandImg" src={CompanyLogo} alt="Logo" />
          </div>
        </div>
      </Nav>
    </>
  );
}

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  padding: 1rem 1.5rem 0.5rem 1.5rem;
  .toggle {
    .container {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 3rem;
      color: white;
    }
    @media screen and (min-width: 850px) and (max-width: 1080px) {
      .container {
        font-size: 3.5rem;
      }
    }
    @media screen and (min-width: 600px) and (max-width: 850px) {
      .container {
        font-size: 3em;
      }
    }
    @media screen and (min-width: 450px) and (max-width: 600px) {
      .container {
        font-size: 2rem;
      }
    }
    @media screen and (min-width: 350px) and (max-width: 450px) {
      .container {
        font-size: 1.5rem;
      }
    }
    @media screen and (max-width: 350px) {
      .container {
        font-size: 1rem;
      }
    }
  }
  .title {
    .container {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: auto;
      height: 5rem;
      overflow: hidden;
      .titleImg {
        height: 100%;
        object-fit: cover;
      }
    }
    @media screen and (min-width: 850px) and (max-width: 1080px) {
      .container {
        height: 5rem;
      }
    }
    @media screen and (min-width: 600px) and (max-width: 850px) {
      .container {
        height: 3rem;
      }
    }
    @media screen and (min-width: 450px) and (max-width: 600px) {
      .container {
        height: 2.5rem;
      }
    }
    @media screen and (min-width: 350px) and (max-width: 450px) {
      .container {
        height: 2rem;
      }
    }
    @media screen and (max-width: 350px) {
      .container {
        height: 1.5rem;
      }
    }
  }
  .brand {
    .container {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: auto;
      height: 7rem;
      overflow: hidden;
      .brandImg {
        height: 100%;
        object-fit: cover;
      }
    }
    @media screen and (min-width: 850px) and (max-width: 1080px) {
      .container {
        height: 7rem;
      }
    }
    @media screen and (min-width: 600px) and (max-width: 850px) {
      .container {
        height: 5rem;
      }
    }
    @media screen and (min-width: 450px) and (max-width: 600px) {
      .container {
        height: 3.5rem;
      }
    }
    @media screen and (min-width: 350px) and (max-width: 450px) {
      .container {
        height: 3rem;
      }
    }
    @media screen and (max-width: 350px) {
      .container {
        height: 2rem;
      }
    }
  }
`;
